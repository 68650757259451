.col-custom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
}

.col-custom img {
    margin: 0;
    width: 100%;
    visibility: visible;
}